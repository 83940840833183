define([
    'angular'
], function () {
    'use strict';
    const services = angular.module('app.newProformaServices', [])
                            .factory("NewProformaFactory", ['$http','GENERAL_CONFIG', 'USER_SETTINGS', 'GlobalService', function ($http, GENERAL_CONFIG, USER_SETTINGS, GlobalService) {
                                const requestUrlBuilder = {
                                    url: '',
                                    isFirstParam: true,
                                    set: function (url) {
                                        this.url = url;
                                        this.isFirstParam = true;
                                        return this;
                                    },
                                    get: function () {
                                        return this.url;
                                    },
                                    appendEndpoint: function (endpoint) {
                                        this.url = this.url + endpoint;
                                        return this;
                                    },
                                    appendParam: function (paramName, paramVal) {
                                        if (!this.url || this.url.length === 0) throw Error('Bad Url');
                                        if (paramName !== undefined && paramVal !== undefined) {
                                            this.url = this.url + (this.isFirstParam ? '?' : '&') + paramName + '=' + paramVal;
                                            this.isFirstParam = false;
                                        }
                                        return this;
                                    }
                                };
                                const newProformaFactory = {};
                                newProformaFactory.sharedStates = {};

                                newProformaFactory.resetStates = function () {
                                    Object.keys(newProformaFactory.sharedStates).forEach((key) => {
                                        newProformaFactory.sharedStates[key] = false;
                                    });
                                }

                                newProformaFactory.getJurisdictions = function () {
                                    return $http.post(`${GENERAL_CONFIG.base_url}/loadJsonObject?action_id=31181&sso_id=${USER_SETTINGS.user.sso_id}`);
                                }

                                newProformaFactory.getFilingGroups = function (tax_year) {
                                    return $http.post(`${GENERAL_CONFIG.base_url}/loadJsonObject?action_id=762&sso_id=${USER_SETTINGS.user.sso_id}&tax_year=${tax_year}`);
                                }
                                

                                newProformaFactory.getTaxPayers = function (tax_year, consol_group_key,isState,scenario,jcd_key) {
                                    if(!isState)
                                        return $http.post(`${GENERAL_CONFIG.base_url}/loadJsonObject?action_id=31188&scenario=${scenario}&jcd_key=${jcd_key}&tax_year=${tax_year}&filing_key=${consol_group_key}&sso_id=${USER_SETTINGS.user.sso_id}`);
                                    else
                                        return $http.post(`${GENERAL_CONFIG.base_url}/loadJsonObject?action_id=31857&tax_year=${tax_year}&scenario=${scenario}&jcd_key=${jcd_key}&sso_id=${USER_SETTINGS.user.sso_id}`);
                                }

                                newProformaFactory.getStates = function (tax_year) {
                                    return $http.post(`${GENERAL_CONFIG.base_url}/loadJsonObject?action_id=31185&sso_id=${USER_SETTINGS.user.sso_id}&tax_year=${tax_year}`);
                                }

                                newProformaFactory.getSystems = function () {
                                    return $http.post(`${GENERAL_CONFIG.base_url}/loadJsonObject?action_id=3118`);
                                }

                                newProformaFactory.getCombinationKeysByEntity = function (taxYear, leId, leType) {
                                    return $http.post(requestUrlBuilder.set(GENERAL_CONFIG.base_url)
                                                                       .appendEndpoint('/loadJsonObject')
                                                                       .appendParam('sso_id', USER_SETTINGS.user.sso_id)
                                                                       .appendParam('tax_year', taxYear)
                                                                       .appendParam('action_id', '32036')
                                                                       .appendParam('leid', leId)
                                                                       .appendParam('le_type', leType)
                                                                       .get());
                                }

                                newProformaFactory.getGridData = function (refresh_data_flag, tax_year, filing_type, scenario, jcd_key, filing_key, tax_payer, filing_state) {
                                    return $http.post(`${GENERAL_CONFIG.base_url}/loadJsonObject?action_id=31186&refresh_data_flag=${refresh_data_flag}&tax_year=${tax_year}&filing_type=${filing_type}&scenario=${scenario}&jcd_key=${jcd_key}&filing_key=${filing_key}&tax_payer=${tax_payer}&filing_state=${filing_state}`);
                                }

                                //Used in Form Review
                                newProformaFactory.fillFormAndMergeNewPorformaAsync = function (formSub, job_id = '', irs_form_no_list, form_no_list, s3_url_list, form_sub_cat, entity, tax_year, scenario, cdr_no, rpt_period, le_type, system_flag, schedule_list, line_list, partNo_list, leName, ein, selectedEntityType = '', selectedConsolGroup = '') {
                                    const promise = $http({
                                        method: 'post',
                                        url: `${GENERAL_CONFIG.pdf_engine_api_url}/fillFormAndMergeNewPorformaAsync?job_id=${job_id}&irs_parent_form_no_list=${irs_form_no_list}&irs_form_no_list=${form_no_list}&form_sub=${formSub}&entity=${entity}&tax_year=${tax_year}&cdr_no=${cdr_no}&rpt_period=${rpt_period}&le_type=${le_type}&system_flag=${system_flag}&schedule_list=${schedule_list}&line_list=${line_list}&partNo_list=${partNo_list}&jcdKey=250&scenario=${scenario}&leName=${encodeURIComponent(leName)}&ein=${ein}&entity_type=${selectedEntityType}&cgkey=${selectedConsolGroup}`,
                                        responseType: 'arraybuffer'
                                    });
                                    return promise;
                                }

                                //Used in New Proforma
                                newProformaFactory.fillFormAndMergeNewPorformaAsyncService = function (formSub, job_id = '', irs_form_no_list, form_no_list, s3_url_list, form_sub_cat, entity, tax_year, scenario, cdr_no, rpt_period, le_type, system_flag, schedule_list, line_list, partNo_list, leName, ein, selectedEntityType = '', selectedConsolGroup = '', srcType = '', parentLeId = '', consolType = '', keyId = '', formType = '') {
                                    const promise = $http({
                                        method: 'post',
                                        url: `${GENERAL_CONFIG.pdf_engine_api_url}/fillFormAndMergeNewPorformaAsyncService?job_id=${job_id}&irs_parent_form_no_list=${irs_form_no_list}&irs_form_no_list=${form_no_list}&form_sub=${formSub}&entity=${encodeURIComponent(entity)}&tax_year=${tax_year}&cdr_no=${cdr_no}&rpt_period=${rpt_period}&le_type=${le_type}&system_flag=${system_flag}&schedule_list=${schedule_list}&line_list=${line_list}&partNo_list=${partNo_list}&jcdKey=250&scenario=${scenario}&leName=${encodeURIComponent(leName)}&ein=${ein}&srcType=${srcType}&entity_type=${selectedEntityType}&cgkey=${selectedConsolGroup}&parentLeId=${parentLeId}&consolType=${consolType}&keyId=${keyId}&formType=${formType}`,
                                        responseType: 'arraybuffer'
                                    });
                                    return promise;
                                }

                                newProformaFactory.fillFormNewProforma = function (formSub = '', irs_form_no, job_id, s3_url, form_sub_cat, entity, tax_year, cdr_no, rpt_period, system_flag = '', wp_irs_form_no = '', schedule = '', line = '', partNo = 'NA', leName, ein = '', scenario, leType, selectedEntityType = '', selectedConsolGroup = '') {
                                    const jcdKey = 250;
                                    const promise = $http({
                                        method: 'post',
                                        url: `${GENERAL_CONFIG.pdf_engine_api_url}/fillFormNewPorforma?irs_form_no=${irs_form_no}&job_id=${job_id}&s3_url=${s3_url}&form_sub_cat=${form_sub_cat}&form_sub=${formSub}&entity=${entity}&tax_year=${tax_year}&cdr_no=${cdr_no}&rpt_period=${rpt_period}&system_flag=${system_flag}&wp_irs_form_no=${wp_irs_form_no}&schedule=${schedule}&line=${line}&partNo=${partNo}&leName=${encodeURIComponent(leName)}&ein=${ein}&jcdKey=${jcdKey}&scenario=${scenario}&leType=${leType}&entity_type=${selectedEntityType}&cgkey=${selectedConsolGroup}`,
                                        responseType: 'arraybuffer'
                                    });
                                    return promise;
                                }

                                newProformaFactory.getBulkExportForms = function (taxYear, returnType, srcType, taxPayer = '', submissionId = '', formType = '') {
                                    return $http.post(requestUrlBuilder.set(GENERAL_CONFIG.pdf_engine_api_url)
                                                                       .appendEndpoint('/getBulkExportForms')
                                                                       .appendParam('taxYear', taxYear)
                                                                       .appendParam('submissionId', submissionId)
                                                                       .appendParam('returnType', returnType)
                                                                       .appendParam('srcType', srcType)
                                                                       .appendParam('parentLeid', taxPayer)
                                                                       .appendParam('formType', formType)
                                                                       .get());
                                };

                                newProformaFactory.pdfsBulkExport = function (
                                    taxYear,
                                    scenario,
                                    parentLeId,
                                    mode,
                                    format,
                                    srcType,
                                    returnType,
                                    businessType,
                                    jcdKey = '250',
                                    groupKey = '',
                                    requestId = '0',
                                    requestedBy = '0',
                                    taxPayerName,
                                    entities,
                                    formType = ''
                                ) {
                                    return $http({
                                        url: requestUrlBuilder.set(GENERAL_CONFIG.pdf_engine_api_url)
                                                              .appendEndpoint('/pdfsBulkExport')
                                                              .appendParam('taxYear', taxYear)
                                                              .appendParam('scenario', scenario)
                                                              .appendParam('parentLeId', parentLeId)
                                                              .appendParam('mode', mode)
                                                              .appendParam('format', format)
                                                              .appendParam('srcType', srcType)
                                                              .appendParam('returnType', returnType)
                                                              .appendParam('businessType', businessType)
                                                              .appendParam('jcdKey', jcdKey)
                                                              .appendParam('groupKey', groupKey)
                                                              .appendParam('requestId', requestId)
                                                              .appendParam('requestedBy', requestedBy)
                                                              .appendParam('taxPayerName', taxPayerName)
                                                              .appendParam('formType', formType)
                                                              .get(),
                                        method: 'POST',
                                        data: JSON.stringify(entities),
                                        headers: {'Content-Type': 'application/json'}
                                    });
                                };

                                newProformaFactory.getXmlData = function (tax_year, leid, cdrno, reporting_period, irs_form_no) {
                                    return $http.get(`${GENERAL_CONFIG.base_url}/efile/getXMLInfo?tax_year=${tax_year}&leid=${leid}&cdrno=${cdrno}&reporting_period=${reporting_period}&irs_form_no=${irs_form_no}`);
                                };

                                newProformaFactory.downloadS3 = function (s3Url) {
                                    let apiTokenUrl = GENERAL_CONFIG.base_url + '/getAPIAuthToken';
                                    return $http.get(apiTokenUrl).then(function (_data){
                                        console.log("_data of apiTokenUrl apiTokenUrl",_data);
                                        let downloadURL = requestUrlBuilder.set(GENERAL_CONFIG.bulk_pdf_engine_api_url)
                                                        .appendEndpoint('/downloadBulkExportFileFromS3Url')
                                                        .appendParam('s3_url',s3Url)
                                                        .appendParam('client_key', sessionStorage.getItem('client_key'))
                                                        .appendParam('apiToken', encodeURIComponent(_data.data.apiToken))
                                                        .appendParam('georaclehrid', USER_SETTINGS.user.sso_id).get();

                                        window.open(downloadURL,'blank');
                                    });
                                }
                                newProformaFactory.getRequestDetails = function (request_id) {
                                    return $http.post(`${GENERAL_CONFIG.base_url}/loadJsonObject?action_id=31506&sso_id=${USER_SETTINGS.user.sso_id}&p_request_id=${request_id}`);
                                }

                                newProformaFactory.getGtwView = function (src_system_flag, lEType, taxYear, scenario, isState, formType = '') {
                                    let apiMethod = isState ? 'getStateGroupView' : 'getGtwView';
                                    if (lEType === 'CONSOL') lEType = 'CON';
                                    const JCD_KEY = isState ? 253 : 250;
                                    const promise = $http({
                                        method: "GET",
                                        url: GENERAL_CONFIG.pdf_engine_api_url + '/' + apiMethod + '?taxYear=' + taxYear + '&scenario=' +
                                            scenario + '&jcdKey=' + JCD_KEY + '&leType=' + lEType + '&entityType=' + '&src_system_flag=' + src_system_flag
                                            + '&formType=' + formType
                                    });
                                    return promise;
                                };
                                
                                newProformaFactory.tabList = [];
                                newProformaFactory.setTabData = function(data){
                                   return newProformaFactory.tabList = data;
                                }
                                return newProformaFactory;
                            }]);

    return services;
});
