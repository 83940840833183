
define([
    'angular'

], function () {
    'use strict';


var services =  angular.module('app.batchMonitoringService',[])

    .factory('batchMonitoringServiceFactory', ['$q', '$http', '$log','$rootScope','AlertService','GlobalService','JsonObjectFactory','workspaceFactory','GENERAL_CONFIG', function($q, $http, $log, $rootScope, AlertService, GlobalService, JsonObjectFactory, workspaceFactory, GENERAL_CONFIG){
        var batchMonitoringServiceFactory = {};
        
        batchMonitoringServiceFactory.getMonitoringData = function(params){
            var params = params;
            var promise = $http({
                method: "get",
                url: GENERAL_CONFIG.base_url + '/getRequestInputData?request_id=' + params.request_id + '&ist_tkn=' + encodeURIComponent(params.ist_tkn),
                data: params
            }).then(function (response) {
                return response;
            });
            return promise;
        };
        
        return batchMonitoringServiceFactory
    }])

    return services;

});