define(["angular"], function () {
    "use strict";

    var controllers = angular
        .module("app.amendedCycleController", [])
        .controller("amendedCycleCtrl", [
            'GlobalService', 'JsonObjectFactory', '$uibModalInstance', '$parse', '$scope', '$rootScope', '$timeout', 'workspaceFactory', '$http', '$q', 'AlertService', 'GENERAL_CONFIG', 'USER_SETTINGS', 'scenarioSetupActionsService', '$filter', 'TAX_YEARS', 'rowData', 'gridData',
            amendedCycleCtrl,
        ]);

    function amendedCycleCtrl(GlobalService, JsonObjectFactory, $uibModalInstance, $parse, $scope, $rootScope, $timeout, workspaceFactory, $http, $q, AlertService, GENERAL_CONFIG, USER_SETTINGS, scenarioSetupActionsService, $filter, TAX_YEARS, rowData, gridData) {
        var vm = this;

        vm.modalTitle = "Amended Cycle";
        vm.isEdit = false;
        vm.dataType = ['CODE_COMBINATION','ENTITY','CHART_ACCT'];
        vm.amendedCycle = {
            TAX_YEAR: workspaceFactory.activeScreen.filters.getFilterParams().tax_year,
            FROM_GROUP_OBJ_KEY: '',
            TO_GROUP_OBJ_KEY: '',
            DATA_TYPE: '',
            CHART_KEY: '',
            ACCT_KEY: '',
            COMBINATION_KEY: '',
            LE_KEY: '',
            ME_KEY: '',
        }
        if (rowData) {
            vm.isEdit = true;
            vm.amendedCycle = rowData;
        }
        vm.dateOptions = {};
        vm.showEntityLoader = false;
        vm.isSaving = false;
        vm.amendedCycle_backup = vm.amendedCycle;

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        }

        vm.reset = function () {
            vm.amendedCycle = {
                TAX_YEAR: workspaceFactory.activeScreen.filters.getFilterParams().tax_year,
                FROM_GROUP_OBJ_KEY: '',
                TO_GROUP_OBJ_KEY: '',
                DATA_TYPE: '',
                CHART_KEY: '',
                ACCT_KEY: '',
                COMBINATION_KEY: '',
                LE_KEY: '',
                ME_KEY: '',
            }
        }

        vm.isValid = function () {
            if (vm.amendedCycle.TAX_YEAR == null ||
                vm.amendedCycle.TAX_YEAR == undefined ||
                vm.amendedCycle.TAX_YEAR == "") {
                vm.amendedCycle.TAX_YEAR_NOT_VALID = true;
                return false;
            }
            if (vm.amendedCycle.FROM_GROUP_OBJ_KEY == null ||
                vm.amendedCycle.FROM_GROUP_OBJ_KEY == undefined ||
                vm.amendedCycle.FROM_GROUP_OBJ_KEY == "") {
                vm.amendedCycle.FROM_GROUP_OBJ_KEY_NOT_VALID = true;
                return false;
            }
            if (vm.amendedCycle.TO_GROUP_OBJ_KEY == null ||
                vm.amendedCycle.TO_GROUP_OBJ_KEY == undefined ||
                vm.amendedCycle.TO_GROUP_OBJ_KEY == "") {
                vm.amendedCycle.TO_GROUP_OBJ_KEY_NOT_VALID = true;
                return false;
            }

            if (vm.amendedCycle.DATA_TYPE == null ||
                vm.amendedCycle.DATA_TYPE == undefined ||
                vm.amendedCycle.DATA_TYPE == "") {
                vm.amendedCycle.DATA_TYPE_NOT_VALID = true;
                return false;
            }
            if (vm.amendedCycle.CHART_KEY == null ||
                vm.amendedCycle.CHART_KEY == undefined ||
                vm.amendedCycle.CHART_KEY == "") {
                vm.amendedCycle.CHART_KEY_NOT_VALID = true;
                return false;
            }
            if (vm.amendedCycle.ACCT_KEY == null ||
                vm.amendedCycle.ACCT_KEY == undefined ||
                vm.amendedCycle.ACCT_KEY == "") {
                vm.amendedCycle.ACCT_KEY_NOT_VALID = true;
                return false;
            }
            if (vm.amendedCycle.COMBINATION_KEY == null ||
                vm.amendedCycle.COMBINATION_KEY == undefined ||
                vm.amendedCycle.COMBINATION_KEY == "") {
                vm.amendedCycle.COMBINATION_KEY_NOT_VALID = true;
                return false;
            }
            if (vm.amendedCycle.LE_KEY == null ||
                vm.amendedCycle.LE_KEY == undefined ||
                vm.amendedCycle.LE_KEY == "") {
                vm.amendedCycle.LE_KEY_NOT_VALID = true;
                return false;
            }
            if (vm.amendedCycle.ME_KEY == null ||
                vm.amendedCycle.ME_KEY == undefined ||
                vm.amendedCycle.ME_KEY == "") {
                vm.amendedCycle.ME_KEY_NOT_VALID = true;
                return false;
            }
            return true;
        }

        vm.save = function () {
            if (!vm.isValid()) {
                AlertService.add("warn", "Please fill all the required fields", 1000);
                return false;
            }
            vm.isSaving = true;
            vm.amendedCycle.EDIT_FLAG = vm.isEdit ? 'U' : 'I';
            // if (vm.amendedCycle.RETURN_DATE)
            //     vm.amendedCycle.RETURN_DATE = moment(vm.amendedCycle.RETURN_DATE).format('MM/DD/YYYY');
            // if (vm.amendedCycle.FILER_TAX_YEAR_BEGIN)
            //     vm.amendedCycle.FILER_TAX_YEAR_BEGIN = moment(vm.amendedCycle.FILER_TAX_YEAR_BEGIN).format('MM/DD/YYYY');
            // if (vm.amendedCycle.FILER_TAX_YEAR_END)
            //     vm.amendedCycle.FILER_TAX_YEAR_END = moment(vm.amendedCycle.FILER_TAX_YEAR_END).format('MM/DD/YYYY');


            var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
            let jsonSettings = {
                tax_year: workspaceFactory.activeScreen.filters.getFilterParams().tax_year

            };
            jsonSettings = JSON.stringify(jsonSettings);


            let jsonObj = JSON.stringify(vm.amendedCycle);
            let params = filterParams;
            params = _.merge({}, GlobalService.globalParams, params);
            params = _.extend({
                clob_data: jsonObj
            }, params);
            params = _.extend({
                clob_settings: jsonSettings
            }, params);
            const url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=0087ar";

            params.is_issue_key_enabled = 'N';
            JsonObjectFactory.saveJSON(url, params).then(function (data) {
                vm.isSaving = false;

                if (data.callSuccess === "1" && data.errorMessage == "null") {
                    $uibModalInstance.dismiss('cancel');
                    $rootScope.$emit('gridUpdate', {});
                    AlertService.add("success", "Amended Cycle Updated successfully", 4000);
                    console.log("saved", data);
                } else {
                    console.log("error");
                    AlertService.add("error", data.errorMessage, 4000);
                }
            });


        };

    }
    return controllers;
});